@import '../../../styles/var';

.formWrap {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: all .3s;

    pointer-events: none;
    opacity: 0;
    backdrop-filter: blur(20px);
    background: rgba(196, 196, 196, 0.05);

    @media(max-width: 700px) {
        background: rgba(0, 0, 0, 0.7);
    }

    &Active {
        opacity: 1;
        pointer-events: visible;
    }

    .closeArea {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        @media(max-width: 700px) {
            display: none;
        }
    }
}

.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media(max-width: 700px) {
        display: block !important;
        padding-top: 120px !important;
        padding-bottom: 120px !important;
        overflow: scroll;
    }

    @media(max-height: 600px) {
        overflow: scroll;
        align-items: flex-start;
    }
}

.form {
    position: relative;
    z-index: 5;
    width: 626px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.7);
    padding: 50px;

    @media(max-width: 700px) {
        background: transparent;
        padding: 0;
        width: 100%;
        border-radius: 0;
    }

    &.formSuccess {
        .formContent {
            filter: blur(6px);
            pointer-events: none;
        }

        .successPopup {
            pointer-events: visible;
            opacity: 1;

            &Img {
                &::before {
                    transform: scale(1) !important;
                }
            }
        }
    }

    .close {
        position: absolute;
        z-index: 20;
        width: 20px;
        height: 20px;
        top: 0;
        right: -40px;
        cursor: pointer;
        transition: all .1s;

        @media(max-width: 700px) {
            top: -50px !important;
            right: 0 !important;
        }

        @media(max-height: 500px) {
            top: 10px;
            right: 10px;
        }

        &:hover {
            transform: scale(0.95);
        }

        &::before {
            content: '';
            position: absolute;
            width: 28px;
            height: 2px;
            background: #fff;
            transform: rotate(45deg);
            top: 49%;
            left: -5px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 28px;
            height: 2px;
            background: #fff;
            transform: rotate(-45deg);
            top: 49%;
            left: -5px;
        }
    }

    .successPopup {
        position: absolute;
        border-radius: 20px;
        width: 100%;
        height: 100%;
        background: rgba($color: #000, $alpha: 0.3);
        z-index: 99999999;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        gap: 30px;
        flex-direction: column;
        text-align: center;

        @media(max-width: 700px) {
            background: transparent;
        }

        @media(max-width: $md6+px) {
            gap: 20px;
        }

        &Text {
            font-size: 16px !important;
            line-height: 150%;

            @media(max-width: 700px) {
                max-width: 350px;
            }
        }

        &Img {
            position: relative;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media(max-width: $md6+px) {
                width: 40px;
                height: 40px;
            }

            svg {
                transition: all .3s;

                @media(max-width: $md6+px) {
                    transform: scale(0.7);
                }
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                border: 4px solid #fff;
                transform: scale(1.5);
                transition: all .3s;

                @media(max-width: $md6+px) {
                    border-width: 3px;
                }
            }
        }
    }

    &Content {
        position: relative;
        z-index: 10;
        transition: all .3s;

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .inputWrap {
                width: 100%;
                border-radius: 30px;
                border: 1px solid #C6AA73;
                padding: 1px;
                height: 50px;

                @media(max-width: $md6+px) {
                    height: 46px;
                }

                &.borderRed {
                    border-color: red !important;
                }

                .input {
                    width: 100%;
                    height: 100%;
                    padding: 0 30px;
                    background: transparent;
                    color: #FFF;
                    font-family: $sans;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;

                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }

                    &::placeholder {
                        color: rgba($color: #fff, $alpha: 0.5);
                    }
                }
            }

            .check {
                display: grid;
                grid-template-columns: 16px 1fr;
                gap: 10px;

                &Red {
                    .checkText {
                        color: red;
                    }
                }

                &Box {
                    position: relative;
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                    border: 1px solid #C6AA73;

                    &::before {
                        content: '';
                        opacity: 0;
                        position: absolute;
                        background: #C6AA73;
                        width: 10px;
                        height: 10px;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        transition: all .1s;
                    }

                    &Active {
                        &::before {
                            opacity: 1;
                        }
                    }
                }

                &Text {
                    font-size: 14px;
                    line-height: 140%;

                    a {
                        cursor: pointer;
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }

            .row {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;

                @media(max-width: 700px) {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }

                .col {}
            }
        }

        .btnWrap {
            display: flex;
            justify-content: flex-end;
            margin-top: 25px;

            .button {
                padding: 13px 30px;
                cursor: pointer;
                position: relative;
                display: inline-flex;
                background: transparent;
                border-radius: 30px;
                transition: all 0.3s, box-shadow 0s;
                border: 1px solid #C6AA73;

                color: #C6AA73;

                font-family: $deco;
                font-size: 20px;
                line-height: 100%;
                font-weight: 400;
                line-height: normal;

                @media(max-width: $md4+px) {
                    font-size: 30px;
                }

                @media(max-width: $md6+px) {
                    font-size: 18px;
                }

                &:hover {
                    color: #000;
                    background: #C6AA73;
                }

                &:active {
                    transform: scale(0.98);
                }
            }
        }
    }
}