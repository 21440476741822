@import "./_var.scss";

body,
html {
    background-color: #000;
    color: #fff;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #000;
    }

    ::-webkit-scrollbar-thumb {
        background: #C6AA73;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgb(204, 183, 142);
    }
}

.font-140 {
    color: #FFF;
    font-family: $one;
    font-size: 140px;
    font-weight: 500;
    line-height: 0.9;
    letter-spacing: 12px;
    text-transform: uppercase;

    @media(max-width: $md4+px) {
        font-size: 90px;
        letter-spacing: 8px;
    }

    @media(max-width: $md5+px) {
        font-size: 70px;
        letter-spacing: 6px;
    }

    @media(max-width: $md6+px) {
        font-size: 45px;
        letter-spacing: 4px;
    }
}

.font-100 {
    color: #FFF;

    font-family: $one;
    font-size: 100px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 9px;
    text-transform: uppercase;


    @media(max-width: $md4+px) {
        font-size: 75px;
        letter-spacing: 6.5px;
    }

    @media(max-width: $md5+px) {
        font-size: 60px;
    }

    @media(max-width: $md6+px) {
        font-size: 40px;
        letter-spacing: 3.5px;
    }
}

.font-80 {
    font-family: $deco;
    font-size: 80px;
    font-weight: 400;
    line-height: 0.8;
    text-transform: uppercase;
    background: linear-gradient(95deg, #F4DBA8 15.58%, #C7AE7B 57.37%, #765D2A 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md4+px) {
        font-size: 50px;
    }

    @media(max-width: $md6+px) {
        font-size: 38px;
    }
}

.font-50 {
    font-family: $deco;
    font-size: 50px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.5px;
    text-transform: lowercase;
    background: linear-gradient(92deg, #F1D8A5 26.58%, #C8AF7C 99.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md4+px) {
        font-size: 35px;
        letter-spacing: 1.5px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
        letter-spacing: 1px;
    }
}

.font-50-undb {
    font-family: $deco;
    font-size: 50px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.5px;
    text-transform: lowercase;
    background: linear-gradient(92deg, #F1D8A5 26.58%, #C8AF7C 99.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md6+px) {
        font-size: 38px;
        letter-spacing: 2px;
    }
}

.font-50-benefits {
    font-family: $deco;
    font-size: 50px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.5px;
    text-transform: lowercase;
    background: linear-gradient(92deg, #F1D8A5 26.58%, #C8AF7C 99.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md6+px) {
        font-size: 22px;
        letter-spacing: 1px;
    }
}

.font-35 {
    font-family: $deco;
    font-size: 35px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: lowercase;
    background: linear-gradient(98deg, #F1D8A5 10.46%, #D2B986 110.79%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md4+px) {
        font-size: 25px;
        letter-spacing: 1px;
    }
}

.font-30 {
    font-family: $deco;
    font-size: 30px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 5px;
    text-transform: lowercase;
    background: linear-gradient(92deg, #F1D8A5 26.58%, #C8AF7C 99.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md6+px) {
        font-size: 22px;
        letter-spacing: 3.5px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-25 {
    color: #FFF;
    text-align: center;
    font-family: $deco;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 8.5px;

    @media(max-width: $md4+px) {
        font-size: 18px;
        letter-spacing: 6.3px;
    }

    @media(max-width: $md6+px) {
        font-size: 15px;
        letter-spacing: 5px;
    }
}

.font-23 {
    font-family: $sans;
    font-size: 23px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 8px;
    text-transform: uppercase;
    background: linear-gradient(92deg, #F1D8A5 26.58%, #C8AF7C 99.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md4+px) {
        font-size: 20px;
        letter-spacing: 7px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 5px;
    }
}

.font-20 {
    text-align: center;
    font-family: $deco;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 1px;
    text-transform: lowercase;
    background: linear-gradient(92deg, #F1D8A5 26.58%, #C8AF7C 99.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md4+px) {
        font-size: 16px;
        letter-spacing: 0.8px;
    }

    @media(max-width: $md6+px) {
        font-size: 14px;
        letter-spacing: 0.7px;
    }
}

.font- {

    @media(max-width: $md4+px) {}

    @media(max-width: $md6+px) {}
}

.font-18-team {
    font-family: $sans;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 12px;
    text-transform: lowercase;
    background: linear-gradient(98deg, #F1D8A5 10.46%, #D2B986 110.79%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md4+px) {
        font-size: 14px;
        letter-spacing: 9px;
    }
}

.font-18-indiv {
    font-family: $sans;
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 6px;
    text-transform: uppercase;
    background: linear-gradient(92deg, #F1D8A5 26.58%, #C8AF7C 99.98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width: $md4+px) {
        font-size: 16px;
        letter-spacing: 5.5px;
    }

    @media(max-width: $md6+px) {
        font-size: 12px;
        letter-spacing: 4px;
    }
}

.font-16 {
    color: #FFF;
    font-family: $sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    @media(max-width: $md4+px) {
        font-size: 14px;
    }

    @media(max-width: $md6+px) {
        font-size: 12px;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mt {
    margin-top: 230px;

    @media (max-width: $md6+px) {
        margin-top: 130px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.Dropdown-control {
    font-family: $deco;
    font-style: normal;
    font-weight: 500;
    font-size: 16px !important;
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
    text-transform: uppercase;
    padding-right: 30px !important;
    cursor: pointer !important;

    &:hover {
        box-shadow: none;
    }
}

.Dropdown-menu {
    font-family: $deco;
    font-style: normal;
    font-size: 14px;
}

.Dropdown-arrow {
    border-color: #fff transparent transparent !important;
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #fff !important;
}