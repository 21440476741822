@import "../../../styles/_var.scss";

.header {
    position: sticky;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s;
    background-color: #000;
    border-bottom: 1px solid #C6AA73;
    -webkit-box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.75);

    &.hideBg {
        // background-color: rgba(255, 255, 255, 0);
    }

    .headerRow {
        display: flex;
        gap: 60px;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;

        @media (max-width: $md6+px) {
            padding: 10px 0;
        }

        &Logo {
            width: 99px;

            img {
                width: 100%;
            }
        }

        &Content {
            display: flex;
            align-items: center;

            .langs {}
        }
    }
}