@import '../../../styles/var';

.underBannerWrap {
    position: relative;
    z-index: 5;
}

.underBanner {
    position: relative;
    max-width: 1080px;
    margin: 0 auto;

    .content {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: space-between;
        justify-items: center;
        gap: 520px 60px;
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 10px;
            justify-content: center;
            align-items: center;
        }
        @media(max-width: $md5+px) {
            gap: 50px;
        }
        .card {
            @media(max-width: $md5+px) {
                transform: none!important;
            }
            
            &Common {

            }
            &Source {
                display: flex;
                align-items: center;
            }

            &:nth-child(1) {
                @media(max-width: $md3+px) {
                    transform: translateX(-150px);
                }
            }

            &:nth-child(2) {
                @media(max-width: $md3+px) {
                    transform: translateX(150px);
                }
            }

            &:nth-child(3) {
                transform: translateX(150px);
                @media(max-width: $md3+px) {
                    transform: translateX(-140px);
                    grid-row-start: 1;
                    grid-row-end: 1;
                }
            }
            
            &:nth-child(4) {
                transform: translateX(-150px);
                @media(max-width: $md3+px) {
                    transform: translateX(140px);
                    grid-row-start: 2;
                    grid-row-end: 2;
                }
            }
        }
    }
}

.lh {
    line-height: 0.8!important;
}