@import '../../../styles/var';

.benefitsWrap {
    position: relative;
    z-index: 10;

    .painting_01 {
        position: absolute;
        left: 0;
        top: 60px;
        width: 218px;

        @media(max-width: $md4+px) {
            width: 140px;
            top: 100px;
        }

        @media(max-width: $md6+px) {
            width: 70px;
        }
    }

    .painting_02 {
        position: absolute;
        right: 0;
        top: 220px;
        width: 273px;

        @media(max-width: $md4+px) {
            width: 170px;
            top: 230px;
        }

        @media(max-width: $md6+px) {
            width: 120px;
            top: 260px;
        }
    }
}

.benefits {
    position: relative;
    z-index: 4;

    .cards {
        display: flex;
        flex-direction: column;
        gap: 40px;
        display: grid;
        grid-template-columns: repeat(1, auto);

        .card {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &:nth-child(2),
            &:nth-child(5) {
                align-items: flex-end;
                text-align: left;
            }

            &:nth-child(3) {
                margin-top: 430px;
            }

            .text_01 {}

            .text_02 {
                margin: 5px 0 15px;
            }

            .line {
                width: 453px;

                @media(max-width: $md4+px) {
                    width: 300px;
                }

                @media(max-width: $md6+px) {
                    width: 200px;
                }
            }
        }

        .car {
            position: relative;
            display: flex;
            margin: 40px auto;
            width: 591px;

            @media(max-width: $md4+px) {
                width: 481px;
                margin: 30px auto;
            }

            @media(max-width: 600px) {
                width: 400px;
            }

            @media(max-width: $md6+px) {
                width: 321px;
                margin: 10px auto;
            }
        }
    }
}