@import '../../../styles/var';

.team {
    .title {
        text-align: center;
    }

    .cards {
        margin-top: 115px;
        display: grid;
        grid-template-columns: repeat(3, 310px);
        grid-template-rows: repeat(3, 310px);
        justify-content: space-between;
        gap: 80px 40px;

        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 310px);
            grid-template-rows: repeat(4, 310px);
            gap: 70px 90px;
            justify-content: center;
        }

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 264px);
            grid-template-rows: repeat(4, 264px);
            margin-top: 80px;
        }

        @media(max-width: 700px) {
            grid-template-columns: repeat(1, 264px);
            grid-template-rows: repeat(7, 264px);
        }

        @media(max-width: $md6+px) {
            gap: 65px;
            margin-top: 60px;
        }

        .card {
            position: relative;
            background-image: url('../../../assets/img/HomePage/Team/bg.webp');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            text-align: center;

            @media(max-width: $md3+px) {
                grid-column-start: initial !important;
                grid-column-end: initial !important;
                grid-row-start: initial !important;
                grid-row-end: initial !important;
            }

            @media (max-width: $md3+px) {
                grid-row: initial !important;
                grid-column: initial !important;
            }

            &:nth-child(1) {
                grid-row: 1/1;
                grid-column: 2/2;
            }

            &:nth-child(2) {
                grid-column: 2/2;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                grid-row: 2/2;
            }

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                grid-row: 3/3;
            }

            .image {
                position: absolute;
                z-index: 2;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 324px;

                @media(max-width: $md4+px) {
                    height: 272px;
                }
            }

            .content {
                position: absolute;
                z-index: 3;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 6px 20px;
                background: linear-gradient(86deg, rgba(255, 255, 255, 0.05) 26.82%, rgba(255, 255, 255, 0.00) 65.65%), rgba(196, 196, 196, 0.05);
                backdrop-filter: blur(15px);
                border-radius: 55px 55px 0 0;
                overflow: hidden;

                @media(max-width: $md4+px) {
                    border-radius: 45px 45px 0 0;
                }

                &Title {}

                &Text {
                    margin-top: -7px;

                    @media(max-width: $md4+px) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}