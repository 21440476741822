@import "../../../styles/var";

.wrap {
    padding-bottom: 60px;
    @media(max-width: $md5+px) {
        padding-bottom: 20px;
    }
}

.footerWrap {
    position: relative;
    height: 272px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: $md5+px) {
        height: auto;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1520px;
        height: 272px;
        background-image: url('../../../assets/img/Footer/bg.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        @media(max-width: $md5+px) {
            display: none;
        }
    }
}

.footer {
    position: relative;

    .content {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 21px;

        @media(max-width: $md5+px) {
            margin: 20px 0;
            gap: 15px;
        }

        a {
            text-decoration: none;
            color: #fff;

            &:hover {
                color: rgba($color: #fff, $alpha: 0.7);
            }
        }

        .docs {
            display: flex;
            align-items: center;
            gap: 70px;
            @media(max-width: $md4+px) {
                gap: 40px;
            }
            @media(max-width: $md5+px) {
                flex-direction: column;
                gap: 10px;
            }
        }
    }

    .line {
        display: none;
        @media(max-width: $md5+px) {
            width: 341px;
            display: block;
        }
    }
}