@import '../../../styles/var';

.bgTriangles {
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0.6;
    @media(max-width: $md3+px) {
        opacity: 0.2;
    }
}

.gold, .rotate, .graphic {
    position: absolute;
}

$goldColor: rgb(169, 133, 69);
$reflectRatio: 12;
$rotateDuration: 6000ms;
$reflectGradient: rgba(169, 133, 69, 0) 0%,
    rgba(169, 133, 69, 0.1) 40%,
    rgba(169, 133, 69, 0.4) 50%,
    rgba(169, 133, 69, 0.1) 60%,
    rgba(169, 133, 69, 0) 100%;

.gold {
    .graphic {
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent rgb(169, 133, 69) transparent;
        animation:
            rotateY $rotateDuration linear infinite,
            goldColor $rotateDuration / 4 linear infinite alternate;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            background: linear-gradient(90deg, $reflectGradient);
            transform: translateX(-50%);
            animation: reflect $rotateDuration / 4 linear infinite alternate;
        }

        &::after {
            content: '';
            position: absolute;
            background: linear-gradient(0deg, $reflectGradient);
            transform: translateY(-50%);
            animation: reflect $rotateDuration / 4 linear infinite alternate;
        }
    }

    @for $i from 1 through 600 {
        $left: random(10) + 2px;
        $right: random(10) + 2px;
        $bottom: random(10) + 2px;
        $delay: random(10000) * -1ms;

        &:nth-child(#{$i}) {
            top: random(100) + 0%;
            left: random(100) + 0%;
            transform: rotateZ(random(360) + 0deg);

            .graphic {
                border-width: 0 $right $bottom $left;
                animation-delay: $delay, $delay;

                &::before {
                    left: -$left + ($left + $right) / 2;
                    width: ($left + $right) * $reflectRatio;
                    height: $bottom;
                    animation-delay: $delay;
                }

                &::after {
                    top: $bottom / 2;
                    left: -$left;
                    width: $left + $right;
                    height: $bottom * $reflectRatio;
                    animation-delay: $delay;
                }
            }

            .rotate {
                animation: rotateZ random(10000) + 10000ms linear infinite;
            }
        }
    }
}

@keyframes rotateZ {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

@keyframes rotateY {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

@keyframes goldColor {
    0% {
        border-color: transparent transparent rgb(255, 255, 255) transparent;
    }
    10% {
        border-color: transparent transparent $goldColor transparent;
    }
    100% {
        border-color: transparent transparent rgb(64, 13, 14) transparent;
    }
}

@keyframes reflect {
    0% {
        opacity: 1;
    }
    10% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}