@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
    font-family: "Great Gatsby One";
    src: url("../assets/fonts/GreatGatsbyOne.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Westbrook Deco";
    src: url("../assets/fonts/WestbrookDeco.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}