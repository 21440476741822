@import "../../../styles/var";

.button {
    padding: 13px 30px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    background: transparent;
    border-radius: 30px;
    transition: all 0.3s, box-shadow 0s;
    border: 1px solid #C6AA73;

    color: #C6AA73;

    font-family: $deco;
    font-size: 40px;
    font-weight: 400;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 30px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }

    &:hover {
        color: #000;
        background: #C6AA73;
    }

    &:active {
        transform: scale(0.98);
    }
}