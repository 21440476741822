@import '../../../styles/var';

.bannerWrap {
    position: relative;

    .frame {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1520px;
        height: 845px;
        background-image: url('../../../assets/img/HomePage/Banner/frame.webp');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @media(max-width: $md4+px) {
            background-image: url('../../../assets/img/HomePage/Banner/frame_plate.webp');
            width: 768px;
            height: 954px;
        }

        @media(max-width: $md6+px) {
            background-image: url('../../../assets/img/HomePage/Banner/frame_mobile.webp');
            width: 375px;
            height: 647px;
        }
    }

    .bg {
        position: absolute;
        z-index: 1;
        top: 460px;
        left: 50%;
        transform: translateX(-50%);
        height: 1353px;
        opacity: 0.6;

        @media(max-width: $md3+px) {
            top: unset;
            bottom: 40px;
            height: 949px;
        }
        
        @media(max-width: $md6+px) {
            bottom: 20px;
            height: 548px;
        }
    }
}

.banner {
    position: relative;
    padding-top: 150px;
    height: 845px;

    @media(max-width: $md3+px) {
        height: auto;
    }
    
    @media(max-width: $md4+px) {
        padding-top: 180px;
    }

    @media(max-width: $md6+px) {
        padding-top: 93px;
    }

    .content {
        position: relative;
        z-index: 20;
        text-align: center;

        .title {
            margin-top: -5px;

            @media(max-width: $md4+px) {
                margin-top: 20px;
            }

            @media(max-width: $md6+px) {
                margin-top: 30px;
            }
        }

        .subtitle {
            margin: 11px auto 26px;

            @media(max-width: $md4+px) {
                margin: 12px auto 35px;
            }

            @media(max-width: $md6+px) {
                margin: 10px auto 30px;
                max-width: 280px;
            }
        }

        .item {
            width: 473px;

            @media(max-width: $md4+px) {
                width: 412px;
            }

            @media(max-width: $md6+px) {
                width: 243px;
            }

            &Rev {
                transform: rotate(180deg);
            }
        }

        .btn {
            margin-top: 70px;

            @media(max-width: $md4+px) {
                margin-top: 100px;
            }

            @media(max-width: $md6+px) {
                margin-top: 50px;
            }
        }
    }

    .girl {
        position: absolute;
        z-index: 19;
        height: 980px;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(-980px - 190px);

        @media(max-width: $md3+px) {
            position: relative;
            left: unset;
            bottom: unset;
            transform: unset;
            display: flex;
            margin: 90px auto 0;
            height: 852px;
        }

        @media(max-width: $md6+px) {
            height: 544px;
            margin-top: 50px;
        }
    }
}