@import '../../../styles/var';

.weOffer {
    position: relative;

    .title {
        text-align: center;
    }

    .cards {
        margin-top: 350px;
        display: grid;
        grid-template-columns: repeat(2, 311px);
        grid-template-rows: repeat(2, 90px);
        justify-content: space-between;
        gap: 236px 60px;

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 250px);
            grid-template-rows: repeat(2, 70px);
            gap: 180px 40px;
            margin-top: 230px;
        }

        @media(max-width: 700px) {
            grid-template-columns: repeat(1, 250px);
            grid-template-rows: repeat(4, 70px);
            justify-content: center;
            gap: 220px;
        }

        @media(max-width: $md6+px) {
            margin-top: 175px;
            gap: 175px;
        }

        .card {
            position: relative;
            width: 100%;
            height: 100%;

            .content {
                background: linear-gradient(86deg, rgba(255, 255, 255, 0.00) -20.51%, rgba(255, 255, 255, 0.05) 26.82%, rgba(255, 255, 255, 0.00) 65.65%), rgba(196, 196, 196, 0.05);
                box-shadow: 0px -2px 1px 0px rgba(255, 255, 255, 0.10) inset;
                backdrop-filter: blur(15px);
                border-radius: 0 0 55px 55px;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 3;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 100%;

                @media(max-width: $md4+px) {
                    border-radius: 0 0 45px 45px;
                }

                .text {
                    position: relative;
                    z-index: 100;
                }
            }

            .image {
                position: absolute;
                z-index: 4;
                left: 50%;
                transform: translateX(-50%);
            }

            .item {
                position: absolute;
                z-index: 1;
                width: 37px;

                @media(max-width: $md4+px) {
                    width: 30px;
                }

                &_01 {
                    left: 20px;
                }
            }

            &:nth-child(1) {
                .item {
                    left: 48px;
                    top: 66px;

                    @media(max-width: $md4+px) {
                        left: 32px;
                        top: 40px;
                    }

                    @media(max-width: $md6+px) {
                        left: 10px;
                        top: 5px;
                    }
                }

                .image {
                    width: 234px;
                    top: -221px;

                    @media(max-width: $md4+px) {
                        width: 142px;
                        top: -150px;
                    }

                    @media(max-width: $md6+px) {
                        width: 118px;
                        top: -115px;
                    }
                }
            }

            &:nth-child(2) {
                .item {
                    right: -15px;
                    top: 0;

                    @media(max-width: $md4+px) {
                        right: 0;
                        top: 10px;
                    }

                    @media(max-width: $md6+px) {
                        top: 20px;
                    }
                }

                .image {
                    top: -284px;
                    width: 186px;
                    z-index: 2;

                    @media(max-width: $md4+px) {
                        width: 107px;
                        top: -150px;
                    }

                    @media(max-width: $md6+px) {
                        top: unset;
                        bottom: 10px;
                        left: 54%;
                    }
                }
            }

            &:nth-child(3) {
                left: 240px;

                @media(max-width: $md3+px) {
                    left: 100px;
                }

                @media(max-width: 850px) {
                    left: 40px;
                }

                @media(max-width: 700px) {
                    left: unset;
                }

                .item {
                    right: 62px;
                    top: 23px;

                    @media(max-width: $md4+px) {
                        right: 30px;
                    }

                    @media(max-width: $md6+px) {}
                }

                .image {
                    top: -151px;
                    width: 269px;

                    @media(max-width: $md4+px) {
                        width: 189px;
                        top: -105px;
                    }

                    @media(max-width: $md6+px) {
                        width: 157px;
                    }
                }
            }

            &:nth-child(4) {
                right: 240px;

                @media(max-width: $md3+px) {
                    right: 100px;
                }

                @media(max-width: 850px) {
                    right: 40px;
                }

                @media(max-width: 700px) {
                    right: unset;
                }

                .item {
                    left: 4px;
                    top: 10px;

                    @media(max-width: $md4+px) {
                        right: 30px;
                    }

                    @media(max-width: $md6+px) {}
                }

                .image {
                    top: -151px;
                    width: 185px;

                    @media(max-width: $md4+px) {
                        width: 130px;
                        top: -105px;
                    }

                    @media(max-width: $md6+px) {
                        width: 108px;
                    }
                }
            }
        }
    }
}