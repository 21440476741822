@import '../../../styles/var';

.individualOffers {
    z-index: 10;
    position: relative;
    .content {
        position: absolute;
        top: 427px;
        width: 100%;
        z-index: 5;
        @media(max-width: $md4+px) {
            top: 320px;
        }
        @media(max-width: $md6+px) {
            top: 180px;
        }
        .header {
            display: flex;
            flex-direction: column;
            gap: 20px;
            text-align: center;
            @media(max-width: $md4+px) {
                gap: 15px;
            }
            @media(max-width: $md6+px) {
                gap: 8px;
            }
            .title {

            }
            .subtitle {

            }
        }
        .text {
            max-width: 480px;
            margin: 50px auto 100px;
            text-align: center;
            @media(max-width: $md4+px) {
                margin: 40px auto 75px;
                max-width: 430px;
            }
            @media(max-width: $md6+px) {
                max-width: 210px;
                margin: 17px auto 30px;
            }
        }
        .btn {
            text-align: center;
        }
    }
    .bg {
        position: relative;
        z-index: 2;
        width: 770px;
        display: flex;
        // margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
        @media(max-width: $md4+px) {
            width: 590px;
        }
        @media(max-width: $md6+px) {
            width: 335px;
        }
    }
}